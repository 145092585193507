@import '../../../global.scss';
.nav-background {
  position: absolute;
  width: 100%;
  display: flex;
  z-index: 3;
  top: 40px;
  left: 0;
  @include mobile_potrait {
    top: 16px;
    border: none;
    padding: 0;
  }
}
.topbar {
  height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 80px;
  position: relative;
  @media screen and (max-width: 820px) {
    width: 100%;
    padding: 0px 32px;
  }
  @include mobile_potrait {
    padding: 0px 16px;
  }

  .logo {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mobile_potrait {
      margin-left: 0rem;
      height: auto;
    }
   
    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      @include mobile_potrait {
        font-size: 2rem;
      }
      @include mobile_landscape {
        font-size: 2rem;
      }
      img {
        height : 48px;
        @include mobile_potrait {
        }
      }
      h1{
        color: #fff;
        font-size: 22px;
        font-weight: 900;
        margin-left: 1rem;
        @include mobile_potrait {
        }
      }
    }
  }
  .menu{
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 6vw;
    margin-right: auto;
    list-style: none;
    @include pad_potrait {
      display: none;
    }
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      margin-left: 20px;
    }
    @include mobile_potrait {
      display: none;
      
    }
    li{
      margin-right: 2vw;
      @media screen and (max-width: 1024px) and (orientation: portrait) {
        margin-right: 1vw;
      }
      a{
        color: #fff;
        text-transform: capitalize;
        transition: all 0.3s ease;
        font-size: 12px;
        display: flex;
        // overflow: hidden;
        padding-right: 100%;
        white-space: nowrap;
        &:hover{
          font-weight: 600;
          // text-shadow: 1px 1px 1px #fff, -1px -1px 1px #fff, -1px 1px 1px #fff, 1px -1px 1px #fff;
          // text-shadow: 1px 1px 0px #fff, -1px -1px 0px #fff, -1px 1px 0px #fff, 1px -1px 0px #fff;
        }
      }
    }
    .selected{
      font-weight: 600;
    }
  }
  
  .balance{
    display: flex;
    align-items: center;
    grid-area: auto;
    gap: 16px;
    @include mobile_potrait {
      gap: 6px;
      img{
        height: 12px;
      }
    }
    p{
      color: #E2EBFB;
      span{
        color: #75849D;
      }
      @include mobile_potrait {
        font-size: 12px;
      }
    }
  }
  .search_social{
    margin-right: 0px;
    display: flex;
    grid-area: auto;
    gap: 24px;
    @media screen and (max-width: 820px) {
      margin-right: 0px;
    }
    @include mobile_potrait {
      margin-right: 40px;
    }
    .social_links{
      display: flex;
      align-items: center;
      grid-area: auto;
      gap : 16px;
      @media screen and (max-width: 820px) {
        display: none;
      }
      a{
        display: flex;
        color: #75849D;
        font-size: 16px;
        transition: all 0.3s ease;
        opacity: 0.5;
        &:hover{
          opacity: 1;
        }
      }
    }

    .search{
      display: flex;
      align-items: center;
      position: relative;
      @media screen and (max-width: 820px) {
      }
      @include mobile_potrait {
        display: none;
      }
      input{
        background-color: #ffffff00;
        color: #75849D;
        font-size: 14px;
        border: none;
        border-bottom: 1px solid #75849D33;
        transition: all 0.3s ease;
        padding-right: 40px;
        max-width: 200px;
        @media screen and (max-width: 820px) {
          max-width: 150px;
        }
        &:hover{
          border-color: #75849D;
        }
        &:focus{
          outline: none;
        }

      }
      button{
        position: absolute;
        right: 10px;
        border: none;
        padding: 0;
        background-color: #ffffff00;
        cursor: pointer;
        img{
          transition: all 0.3s ease;
        }
        &:hover{
          img{
            filter: brightness(2);
          }
        }
      }

    }
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: auto;
    gap: 20px;
    @media screen and (max-width: 1024px) and (orientation: portrait) {
      gap: 10px;
    }
    @include mobile_potrait {
    }
    .iconBtn{
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      @include pad_potrait {
        display: none;
        img{
          height: 16px;
        }
      }

    }
    

    .connectBtn {
      display: flex;
      align-items: center;
      text-decoration: none;
      background: linear-gradient(108.11deg, #7351F8 -9.24%, #5039AC 113.24%);
      padding: 3px 10px;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 14px;
      border: none;
      @include pad_potrait {
        margin-right: 35px;
      }
      @include mobile_potrait {
        font-size: 14px;
        margin: 0px;
        margin-right: 0px;
      }
      &:hover {
        background: linear-gradient(108.11deg, #8164f7 -9.24%, #7455ef 113.24%);
      }
      img{
        margin-right: 0.5rem;
      }
    }
  }

  .hamburger {
    display: none;
    position: absolute;
    z-index: 3;
    overflow: hidden;
    width: 20px;
    height: 20px;
    right: 80px;
    top: 6px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    cursor: pointer;
    overflow: hidden;
    transform: scale(0.9);
    // background : #777;
    // border: 3px #777 solid;
    @media screen and (max-width: 820px) {
      right: 16px;
    }
    
    @include mobile_potrait {
      display: flex;
    }

    span {
      width: 100%;
      height: 2px;
      border-radius: 3px;
      background-color: #75849D;
      transform-origin: left;
      transition: all 1s ease;
    }
    &.active {
      z-index: 1;
      align-items: flex-start;
      span {
        &:first-child {
          width: 22px;
          transform: rotate(38deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          width: 22px;
          transform: rotate(-38deg);
        }
      }
    }
  }
}

@import '../../../global.scss';
.menubar {
  height: 100vh;
  position: fixed;
  left: -100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 1s ease;
  background-color: #000;
  z-index: 1;
  position: fixed;
  top: 0px;
  padding: 20px;
  padding-top: 100px;
  width: 100vw;
  @include mobile_potrait {
    width: 100vw;
    left: -100vw;
    padding-top: 100px;
  }

  &.active {
    left: 0;
  }
  .menuContent {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }

 
}
.sideBar {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  top: 10px;

  @include mobile_potrait {
    width: 100%;
    margin-bottom: 20px;
  }
  @include pad_potrait {
    width: 50%;
    margin-bottom: 20px;
  }
  .navList {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    .menu{
      display: flex;
      padding: 0;
      margin: 0;
      list-style: none;
      flex-direction: column;
      li{
        margin-bottom: 2rem;
        a{
          color: #444;
          transition: all 0.3s ease;
          font-size: 24px;
          font-weight: 400;
          display: flex;
          &:hover{
            color: #fff;
          }
          img{
            margin-right: 16px;
          }
        }
        .dropdown{
          position: relative;
          .dropdown-btn{
            background-color: #ffffff00;
            border: none;
            color: #fff;
            font-size: 1.5rem;
            font-weight: 700;
            text-transform: uppercase;
            img{
              margin-left: 0.5rem;
            }
          }
          .dropdown-content{
            position: absolute;
            display: none;
            min-width: 11rem;
            min-height: 2rem;
            text-align: left;
            padding: .5rem;
            left: 0;
            white-space: normal;
            background-color: #0f0f24;
            background-clip: padding-box;
            // border: 1px solid rgba(0,0,0,.54);
            z-index: 1000;
            border-radius: 0.5rem;
            .list-menu {
              list-style: none;
              padding-left: 0;
              width: 100%;
              .list-menu__item {
                padding: .375rem .75rem;
                cursor: pointer;
                color: #fff;
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 0rem;
                &:hover {
                  color: #fde995;
                }
              }
  
              .list-menu__item--active {
                color: #fde995;
              }
            }
            
          }
          .active-down{
            display: flex;
          }
        }
        
      }
      .selected{
        a{
          color: #fff;
        }
      }
    }
    .connectBtn {
      display: flex;
      align-items: center;
      text-decoration: none;
      user-select: none;
      background-color: transparent;
      line-height: 1.5;
      background: linear-gradient(to right, #0f0f24,#0f0f24) padding-box, linear-gradient(180deg,#ef655dbf 0,#8149bf2e 50%) border-box;
      border: 2px solid transparent;
      padding: .75rem 1.5rem;
      color: #fff;
      border-radius: 50px;
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 16px;
      font-weight: 600;
      
      &:hover {
        border-color: #ef655dbf;
      }
      img{
        margin-right: 0.5rem;
      }
    }
  }



  .sideFooter {
    position: sticky;
    top: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    margin-top: 50px;

    @include mobile_potrait {
      margin-top: 50px;
    }

    .change_theme {
      width: 100%;
      background-color: #F0F2F5;
      padding: 10px;
      display: flex;
      align-items: center;
      border-radius: 50px;
      grid-template-columns: auto auto auto;
      gap: 10px;

      button {
        border: none;
        background-color: #F0F2F5;
        color: #9AA4B5;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        width: 50%;
        padding: 10px 0px;
        transition: all 0.3s ease;
        cursor: pointer;

        img {
          transition: all 0.3s ease;
          margin-right: 7px;
          filter: grayscale(1);
          opacity: 0.7;
        }
      }

      .activeThemeBtn {
        background-color: #fff;
        color: #0841D4;

        img {
          filter: grayscale(0);
          opacity: 1;
        }
      }
    }

    .socialLinks {
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        margin: 10px;
        font-size: 26px;
        color: #1EA1F2;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          opacity: 0.7;
        }
      }

      .medium {
        color: #000;
      }
    }


  }

  .hamburger {
    display: none;
    width: 2.5rem;
    height: 3rem;
    position: absolute;
    right: 2rem;
    top: 1rem;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
    z-index: 6;
    overflow: hidden;
    transform: scale(0.9);

    @media screen and (max-width: 990px) {
      display: flex;
      width: 2rem;
      height: 2rem;
      right: 0.5rem;
      flex-direction: column;
      justify-content: space-around;
      cursor: pointer;
      z-index: 6;
      overflow: hidden;
      transform: scale(0.9);
    }

    @media screen and (max-width: 540px) {
      top: 0;
    }

    @include mobile_landscape {
      width: 2.5rem;
      height: 2.2rem;
      right: 1rem;
      top: 0rem;
      flex-direction: column;
      justify-content: space-around;
      cursor: pointer;
      z-index: 6;
      overflow: hidden;
      transform: scale(0.9);
    }

    span {
      width: 100%;
      height: 0.3rem;
      border-radius: 3px;
      background-color: #fff;
      transform-origin: left;
      transition: all 1s ease;
    }

    &.active {
      z-index: 1;

      span {
        &:first-child {
          width: 2.2rem;
          transform: rotate(40deg);

          @include mobile_potrait {
            transform: rotate(35deg);
          }

          @include mobile_landscape {
            transform: rotate(30deg);
          }
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          width: 2.2rem;
          transform: rotate(-40deg);

          @include mobile_potrait {
            transform: rotate(-35deg);
          }

          @include mobile_landscape {
            transform: rotate(-30deg);
          }
        }
      }
    }
  }
}

@import '../../../global.scss';
.connectModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.61);
  z-index: 5;
  display: none;
  opacity: 1;
  @include mobile_potrait{
    background-color: #000;
}
  &.active {
    display: flex;
  }
  .modelContent {
    z-index: 5;
    width: 380px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    opacity: 1;
    border-radius: 16px;
    background-color: #000;
    padding: 18px;
    @include mobile_potrait {
      width: 90vw;
    }
    @include mobile_landscape {
    }
    
    .connectWalletHeader {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-bottom: 0;
      padding-top: 24px;
      box-sizing: border-box;
      @include mobile_potrait {
        padding-top: 0px;
      }
      .connectWalletTitle {
        color: #fff;
        font-size: 28px;
        @include mobile_potrait {
        }
      }
      .connectModalCloseButton {
        font-size: 16px;
        font-weight: 600;
        width: 36px;
        height : 36px;
        border: none;
        background-color: #fff;
        border-radius: 10px;
        cursor: pointer;
        color: #000;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        &:hover {
          animation: closeAni 0.5s ease 0s infinite;
          @keyframes closeAni {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(0.5rem);
            }
            100% {
              transform: translateX(0);
            }
          }
        }
      }
    }

    .connectWalletWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      p{
        font-size: 12px;
        line-height: 18px;
        color: rgba(255, 255, 255, 0.3);
        margin-bottom: 12px;
      }
      .connectModalBtn {
        cursor: pointer;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background: rgba(247, 248, 249, 0.2);
        padding: 10px 22px;
        margin-bottom: 12px;
        border-radius: 16px;
        transition: all 0.3s ease;
        @include mobile_potrait {
          padding: 5px 22px;
        }
        &:hover {
          background: rgba(247, 248, 249, 0.3);
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          @include mobile_potrait {
          }
          img {
            width: 36px;
            height:36px;
            transition: all 0.3s ease;
            margin-right: 16px;
          }
        }
        h2 {
          font-size: 14px;
          font-weight: normal;
          color: #75849D;
          transition: all 0.3s ease;
          @include mobile_potrait {
          }
          @include mobile_landscape {
          }
        }
      }
    }
  }
}

@import "../../../global.scss";
.accountModal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.61);
    z-index: 5;
    display: none;
    opacity: 1;
    @include mobile_potrait{
        background-color: #000;
    }
    &.active{
        display: flex;
    }
    .modelContent{
        z-index: 5;
        width: 400px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        position: relative;
        opacity: 1;
        border-radius: 10px;
        padding: 16px;
        background-color: #000;
        @include mobile_potrait{
            width: 90vw;
            background-color: #ffffff11;
        }
        animation: modalAni 0.5s ease 0s 1 forwards;
        @keyframes modalAni {
            0%{
                transform: scale(0);
            }
            80%{
                transform: scale(1.2);
            }
            100%{
                transform: scale(1);
            }
        }
        .connectWalletHeader {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 16px;
            padding-top: 24px;
            box-sizing: border-box;

            @include mobile_potrait {
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
            }
            @include mobile_landscape {
              border-top-left-radius: 1rem;
              border-top-right-radius: 1rem;
            }
            .connectWalletTitle {
              color: #fff;
              font-size: 28px;
              @include mobile_potrait {
              }
            }
            .connectModalCloseButton {
              font-size: 16px;
              font-weight: 600;
              width: 36px;
              height : 36px;
              border: none;
              background-color: #fff;
              border-radius: 10px;
              cursor: pointer;
              color: #000;
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.3s ease;
              &:hover {
                animation: closeAni 0.5s ease 0s infinite;
                @keyframes closeAni {
                  0% {
                    transform: translateX(0);
                  }
                  50% {
                    transform: translateX(0.5rem);
                  }
                  100% {
                    transform: translateX(0);
                  }
                }
              }
            }
          }
      
        .modalWraper{
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            z-index: 1;
            .row{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 1rem;
                .avatar{
                    width: 3rem;
                }
                input{
                    border: none;
                    background-color: #ffffff00;
                    margin-left: 1rem;
                    font-size: 1rem;
                    color: rgba(255, 255, 255, 0.3);
                    width: 10rem;
                    &:focus{
                        outline: none;
                    }
                    @include mobile_potrait{
                        width: 6rem;
                    }
                    @include mobile_landscape{
                        width: 6rem;
                    }
                    @include mobile_potrait{
                        font-size: 0.8rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
                .coppyAddressButton{
                    cursor: pointer;
                    border: none;
                    background-color: #ffffff00;
                    color: rgba(255, 255, 255, 0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    transition: all 0.3s ease ;
                    &:hover{
                        text-shadow: 0px 0px 5px #4b747b;
                    }
                    p{
                        margin-left: 0.5rem;
                        @include mobile_potrait{
                            font-size: 0.8rem;
                        }
                        @include mobile_landscape{
                            font-size: 0.8rem;
                        }
                    }
                }
            }
            .modalBtns{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-direction: row;
                margin: 0 !important;
                a{
                    cursor: pointer;
                    border: none;
                    background-color: #ffffff00;
                    color: #888;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1rem;
                    transition: all 0.3s ease ;
                    text-decoration: none;
                    &:hover{
                        text-shadow: 0px 0px 5px #4b747b;
                    }
                    .fas{
                        margin-right: 0.5rem;
                    }
                    @include mobile_potrait{
                        font-size: 0.8rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
                .disconnect{
                    cursor: pointer;
                    font-size: 1rem;
                    padding: 0.5rem 2rem;
                    color: #727272;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 10px;
                    transition: all 0.3s ease;
                    background-color: #E3E3E3;
                    border: 1px #363537 solid;
                    border-radius: 10px;
                    &:hover{
                        box-shadow: 0px 0px 5px #fff;
                        text-shadow: 0px 0px 15px #fff;
                    }
                    @include mobile_potrait{
                        font-size: 0.8rem;
                    }
                    @include mobile_landscape{
                        font-size: 0.8rem;
                    }
                }
            }

        }
        
        .connectBg{
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
